let awsRegion = 'us-west-2';

export default {
 api_urls: {
   reporting: 'https://ac6bxze4d4.execute-api.us-west-2.amazonaws.com/api',
   geojson: 'https://t5gvcaq66k.execute-api.us-west-2.amazonaws.com/api',
   overlays: 'https://aw9xp9t1j8.execute-api.us-west-2.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 6,
 crm_base_url: 'https://crm.dev.muttley.forwoodsafety.com',
 forwood_id_url: 'https://id.dev.muttley.forwoodsafety.com?redirect_uri=https://geoeditor.dev.muttley.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.dev.muttley.forwoodsafety.com',
 Auth: {
   userPoolId: 'us-west-2_9PA0x6zxU',
   userPoolWebClientId: '4847rkndjkqkat3o2mof24mtd2',
   cookieStorage: {
     domain: '.dev.muttley.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'False',
 env: 'dev'
};
